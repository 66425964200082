<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>

<script>

// import { findAlbum } from '@/services/SpotifyService'
export default {
  data () {
    return {}
  },
  created() {
    // console.log(findAlbum('Tape Deck Heart', 'Frank Turner'));
  }
}
</script>